import React, { useEffect, useState, useRef } from "react";
import "./Hour.css"

const Hour = ({ time }) => {
    var AmOrPm = time[0] >= 12 ? 'pm' : 'am';
    var hours = (time[0] % 12) || 12;

    return (
        <>
        {hours}:{time[1].toLocaleString('en-US', {hour12: true, minimumIntegerDigits: 2, useGrouping:false})}{AmOrPm}
        </>
    )
}

export default Hour