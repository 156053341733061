import React, { useEffect, useState, useRef } from "react";
import "./ProductCard.css"

const ProductCard = ({ product }) => {
    return (
        <div className="Product-Card">
            <img src={product.Image}></img>

            <div style={{maxWidth: "90%"}}>
                <span className="Product-Card-Price">
                    {product.OverridePrice ? (
                        <>
                            <span style={{marginRight:5, color: "red", textDecoration: "line-through"}}>
                                ${product.Prices[0].toFixed(2)}
                            </span>
                            ${product.OverridePrice.toFixed(2)}
                        </>
                    ) : 
                       <>
                        ${product.Prices[0].toFixed(2)}
                        </>
                    }
                </span>
                <h2 className="Product-Card-Title">{product.Name}</h2>
                <h5 className="Product-Card-Brand">{product.brandName}</h5>
                <p style={{marginTop:5, fontWeight:'bold'}} className="Product-Card-Info">{product.strainType.toUpperCase()}</p>
                {
                    product.THCContent && product.THCContent.range && product.THCContent.range.length > 0 ? (
                        <p className="Product-Card-Info">
                            {
                                    product.THCContent && product.THCContent.range && product.THCContent.range.length > 0 ? (
                                        product.THCContent.unit === "MILLIGRAMS" ? (
                                            `THC: ${product.THCContent.range[product.THCContent.range.length-1]}mg`
                                        ) : (
                                            `THC: ${product.THCContent.range[0]}% - ${product.THCContent.range[product.THCContent.range.length-1]}%`
                                        )
                                    ) : null
                                }
                        </p>                         
                    ) : null
                }
                {
                    product.CBDContent && product.CBDContent.range && product.CBDContent.range.length > 0 ? (
                        <p className="Product-Card-Info">
                             {
                                    product.CBDContent && product.CBDContent.range && product.CBDContent.range.length > 0 ? (
                                        product.CBDContent.unit === "MILLIGRAMS" ? (
                                            `CBD: ${product.CBDContent.range[product.CBDContent.range.length-1]}mg`
                                        ) : (
                                            `CBD: ${product.CBDContent.range[0]}% - ${product.CBDContent.range[product.CBDContent.range.length-1]}%`
                                        )
                                    ) : null
                            }
                        </p>                         
                    ) : null
                }
            </div>

        </div>
    )
}

export default ProductCard