import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState, useRef } from "react";
import {useForm} from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useParams } from 'react-router-dom'
import {Helmet} from "react-helmet";

function Checkout({}) {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { retailerId } = useParams()


    const [products, setProducts] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [total, setTotal] = useState(0); 
    // const hostname = "http://localhost:5000";
     const hostname = "https://heed.herokuapp.com";
    // Checkout fields
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [type, setType] = useState("pick up");
    const [paymentType, setPaymentType] = useState("pay now");    
    const [cardError, setCardError] = useState(false);
    const [cardValue, setCardValue] = useState("");
    const [expiry, setExpiry] = useState("02/25");
    const [birthdate, setBirthDate] = useState("");
    const [cvv, setCvv] = useState("333");
    const [zipCode, setZipCode] = useState("N5K 3K3");
    const [disabled, setDisabled] = useState(false);

    const [allowInStorePayments, setAllowInStorePayemnts] = useState(false);
    const [retailer, setRetailer] = useState("");
    const [retailerLogo, setRetailerLogo] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("#ffffff");
    const [textColor, setTextColor] = useState("#323232");
    const [secondaryTextColor, setSecondaryTextColor] = useState("#A5A5A5");
    const [accentColor, setAccentColor] = useState("#5096ff");


    useEffect(() => {
        try {

            fetch( `${hostname}/api/retailer/${retailerId}`, {    headers: { 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setRetailer(response.data[0].retailer);
                    setRetailerLogo(response.data[0].logo);
                    if (response.data[0].settings && response.data[0].settings.branding && response.data[0].settings.branding.backgroundColor) {
                        setBackgroundColor(response.data[0].settings.branding.backgroundColor);
                        setTextColor(response.data[0].settings.branding.textColor);
                        setSecondaryTextColor(response.data[0].settings.branding.secondaryTextColor);
                        setAccentColor(response.data[0].settings.branding.accentColor);
                        setAllowInStorePayemnts(response.data[0].settings.payAtStore);
                    }

                }
            })
            .catch((err) => {console.log(err)}); 


            var cartStorage = JSON.parse(localStorage.getItem('heed-cart'));
            if (cartStorage) {
                if (Object.values(cartStorage).length <= 0) {
                    window.location.href = `/${retailerId}`;
                } 
                let productIds = Object.keys(cartStorage);
                let quantities = Object.values(cartStorage).map(arr => arr.length);

                fetch( `${hostname}/api/checkout/${retailerId}/cart`, {    headers: { 'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify({ids: productIds, quantities: quantities}), crossDomain:true })
                .then((response) => response.json())
                .then((response) => {
                    if (response.success) {

                        if (response.products.length == 0) {
                            alert("None of the products in your cart are currently available...");
                            localStorage.setItem('heed-cart', false);
                            window.location.href = `/${retailerId}`;
                        }

                       setProducts(response.products);
                        
                        // Calculate totals
                        var priceAmount = 0;
                        
            
                        for (var i = 0; i < response.products.length; i++) {
                            var product = response.products[i];
                            var pricing = !product.OverridePrice ? product.Prices[0]: product.OverridePrice;
                            var price = pricing * product.ATCQUANTITY;
                            priceAmount+=price;
                        } 
            
                        var taxAmount = parseFloat((priceAmount * 0.13).toFixed(2));
                        var totalAmount = (priceAmount + taxAmount);
                        setSubtotal(priceAmount.toFixed(2));
                        setTax(taxAmount);
                        setTotal(totalAmount.toFixed(2));
                        
                        let message = {
                            height: document.body.scrollHeight,
                            width: document.body.scrollWidth
                          };
                  
                        // window.top refers to parent window
                        window.top.postMessage(message, "*");

                    }
                })
                .catch((err) => {console.log(err)}); 


            } else {
                window.location.href = `/${retailerId}`;
            }
        }
        catch (e) {
            console.log(e);
            window.location.href = `/${retailerId}`;
        }
        
    }, []);

    const setCardNumber = (e) => {
        const val =
          e.target.value
            .replace(/\s|[^0-9]+/g, "")
            .match(/.{1,4}/g)
            ?.join(" ") ?? "";
        console.log(val);
        setCardValue(val);
    };

    const checkValue = (str, max) => {
        if (str.charAt(0) !== '0' || str == '00') {
          var num = parseInt(str);
          if (isNaN(num) || num <= 0 || num > max) num = 1;
          str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
        };
        return str;
    };

    const setBirthday = (e) => {
        var input = e.target.value;
        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
        var values = input.split(' / ').map(function(v) {
          return v.replace(/\D/g, '')
        });
        if (values[0]) values[0] = checkValue(values[0], 12);
        if (values[1]) values[1] = checkValue(values[1], 31);
        var output = values.map(function(v, i) {
          return v.length == 2 && i < 2 ? v + ' / ' : v;
        });
       var val = output.join('').substr(0, 14);
       setBirthDate(val);
    };

    const birthdayBlur = (e) => {
        console.log("blurrr");
        var input = e.target.value;
        var values = input.split(' / ').map(function(v, i) {
            return v.replace(/\D/g, '')
        });
        var output = '';
        
        if (values.length == 3) {
            var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
            var month = parseInt(values[0]) - 1;
            var day = parseInt(values[1]);
            var d = new Date(year, month, day);
            if (!isNaN(d)) {
            var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
            output = dates.map(function(v) {
                v = v.toString();
                return v.length == 1 ? '0' + v : v;
            }).join(' / ');
            };
        };
        setBirthDate(output);
    }


    const onSubmit = (data) => {
        if (disabled) {
            return;
        }
        setDisabled(true);
        var form = {
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            dob: birthdate.replace(" ", ""),
            email: data.email,
            type: type,
            paymentType: paymentType,
            products: products,
            card: paymentType === "pay now" ? {
                cardNumber: data.cardNumber.replace(/\s/g, ''),
                expiry: `${data.expMonth}/${data.expYear}`,
                cvv: data.cvv,
                zipCode: data.zip
            } : {}
        };
        // Fetch request
        fetch( `${hostname}/api/checkout/${retailerId}`, {    headers: { 'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify(form), crossDomain:true })
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                localStorage.setItem('heed-cart', false);
                window.location.href = `/${retailerId}/order/${response.data[0].order}`
            } else {
                setDisabled(false);
                alert(response.message);
            }
        })
        .catch((err) => {
            setDisabled(false);
            console.log(err)});
    }


    return (
        <div className="App Checkout-Page">
             <Helmet>
                <style>
                {`
                    :root {
                    --background-color: ${backgroundColor};
                    --text-color: ${textColor};
                    --secondary-text-color: ${secondaryTextColor};
                    --accent-color: ${accentColor};

                    }
                `}
                </style>

            </Helmet>
            <div className="Checkout">
               <h1 style={{color: textColor}}>Checkout</h1>
                
                <div className="Checkout-Wrapper" style={{borderTop: '0.5px solid #D2D2D2', paddingTop:30, justifyContent:'space-evenly', display:'flex', flexDirection:'row'}}>
                        <div className="Checkout-Container">
                            <form id="checkout-form" onSubmit={handleSubmit(onSubmit)}>
                                <label style={{marginBottom:30}}>Customer</label>
                                {/* Customer */}
            
                                <div className="side side1">

                                    <div style={{display:'flex', flex:1, paddingRight:15, flexDirection: 'column', marginRight:2.5}}>
                                        <label style={{marginBottom:10, fontSize:14}}>First Name</label>
                                        <input {...register("firstName",  { required: "* Please enter field" })} placeholder="First Name"></input>
                                        <ErrorMessage
                                            errors={errors}
                                            name="firstName"
                                            render={({ message }) => <p className="form-error">{message}</p>}
                                        />
      
                            

                                    </div>


                                    <div style={{display:'flex', flex:1, paddingLeft:15, flexDirection: 'column', marginRight:2.5}} >
                                        <label style={{marginBottom:10, fontSize:14}}>Last Name</label>
                                        <input {...register("lastName", { required: "* Please enter field" })} placeholder="Last Name"></input>
                                        <ErrorMessage
                                            errors={errors}
                                            name="lastName"
                                            render={({ message }) => <p className="form-error">{message}</p>}
                                        />
                                    </div>



                                </div>

                                <div style={{display:'flex', flexDirection: 'column', marginTop:5, marginRight:2.5}} >
                                    <label style={{marginBottom:10, fontSize:14}}>Phone Number</label>
                                    <input type="tel" placeholder="Phone Number" {...register("phone", {required: "* Please enter field", maxLength: {value: 12, message: "Please enter a valid phone number"}, minLength: {value: 8, message: "Please enter a valid phone number"}})} />
                                    <ErrorMessage
                                            errors={errors}
                                            name="phone"
                                            render={({ message }) => <p className="form-error">{message}</p>}
                                    />
                                </div>


                                <div style={{display:'flex', flexDirection: 'column', marginTop:5, marginRight:2.5}} >
                                    <label style={{marginBottom:10, fontSize:14}}>Birthday (MM / DD / YYYY)</label>
                                    <input
                                        // {...register("birthday", {  required: "* Please enter field" })} 
                                        // required
                                        value={birthdate} 
                                        style={{width:'100%'}}
                                        placeholder="Birthday"
                                        onChange={(e) => {
                                            setBirthday(e);
                                        }}
                                        onBlur={(e) => {
                                            birthdayBlur(e);
                                        }}
                                    ></input>
                                    <ErrorMessage
                                            errors={errors}
                                            name="birthday"
                                            render={({ message }) => <p className="form-error">{message}</p>}
                                    />
                                </div>

                                <div style={{display:'flex', flexDirection: 'column', marginTop:5, marginRight:2.5}} >
                                    <label style={{marginBottom:10, fontSize:14}}>Email</label>
                                    <input {...register("email", { required: "* Please enter field", pattern:{value:  /^\S+@\S+$/i, message: "* Please enter a valid email"} },)} placeholder="Email"></input>
                                    <ErrorMessage
                                            errors={errors}
                                            name="email"
                                            render={({ message }) => <p className="form-error">{message}</p>}
                                    />
                                </div>



                                {/* Type */}
                                <label style={{marginTop:15}}>Type</label>

                                <input value="Pick up" className="Active-Option" style={{cursor:"pointer", backgroundColor:backgroundColor}} disabled placeholder="Pick up"></input>
                                {/* <input style={{cursor: 'no-drop', backgroundColor:backgroundColor}} disabled placeholder="Delivery"></input> */}

                                {/* Payment */}
                                <label style={{marginTop:15}}>Payment</label>
                                {
                                    allowInStorePayments ? (
                                        <input onClick={() => {setPaymentType("pay at store")}} className={paymentType === "pay at store" ? "Active-Option": ""} style={{textAlign:"left", cursor: 'pointer', backgroundColor:backgroundColor}}  value="Pay at store" type="button" placeholder="Pay at store"></input>
                                    ) : null
                                }
                                <input onClick={() => {setPaymentType("pay now")}} className={paymentType === "pay now" ? "Active-Option": ""} style={{textAlign:"left", cursor:"pointer", backgroundColor:backgroundColor}} value="Pay Now" placeholder="Pay now" type="button"></input>

                                {
                                    paymentType === "pay now" ? (
                                        <>
                                            <div className="Credit-Card-Form">
                                                <label style={{fontSize:14, color: secondaryTextColor}}>Credit Card</label>
                                                <div className="Card-Alert">
                                                    You will be required to show your ID and credit card upon pickup.
                                                </div>
                                                <div style={{marginTop:15, padding:15, paddingTop:15, paddingBottom:15, backgroundColor: backgroundColor, border: '1px solid #D6D6D6', boxSizing: 'border-box', borderRadius:5}}>
                                                    <label style={{fontSize:12, color: secondaryTextColor, marginBottom:15}}>Enter your card details</label>
                                                    <input 
                                                        {...register("cardNumber", { required: "* Please enter field" })}
                                                        value={cardValue}
                                                        onChange={(e) => {
                                                            setCardNumber(e);
                                                        }}
                                                        type="tel"
                                                        inputMode="numeric"
                                                        maxLength={19}
                                                        style={{marginTop:15, width:'100%'}} placeholder="Card Number"></input>
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="cardNumber"
                                                            render={({ message }) => <p className="form-error">{message}</p>}
                                                        />
                                                    <div className="side">
                                                        <select  {...register("expMonth", { required: "* Please enter field" })} style={{flex:1, marginRight:5}}>
                                                            <option>01</option>
                                                            <option>02</option>
                                                            <option>03</option>
                                                            <option>04</option>
                                                            <option>05</option>
                                                            <option>06</option>
                                                            <option>07</option>
                                                            <option>08</option>
                                                            <option>09</option>
                                                            <option>10</option>
                                                            <option>11</option>
                                                            <option>12</option>
                                                        </select>

                                                        <select {...register("expYear", { required: "* Please enter field" })} style={{flex:1, marginRight:5}}>
                                                            <option>2022</option>
                                                            <option>2023</option>
                                                            <option>2024</option>
                                                            <option>2025</option>
                                                            <option>2026</option>
                                                            <option>2027</option>
                                                            <option>2028</option>
                                                            <option>2029</option>
                                                            <option>2030</option>
                                                        </select>


                                                    </div>
                                                    <input style={{width:'100%'}} {...register("cvv", { required: "* Please enter field" })} placeholder="CVV"></input>
                                                        <ErrorMessage
                                                                errors={errors}
                                                                name="cvv"
                                                                render={({ message }) => <p className="form-error">{message}</p>}
                                                    />
                                                    <input {...register("zip", { required: "* Please enter field" })} style={{width:'100%'}} placeholder="Zip/Postal"></input>
                                                    <ErrorMessage
                                                            errors={errors}
                                                            name="zip"
                                                            render={({ message }) => <p className="form-error">{message}</p>}
                                                    />
                                                </div>
                                            </div>

                                            {/* Items */}
                                            {/* <label style={{marginTop:15}}>Subscribe</label>
                                            <div style={{display:'flex'}}>
                                                <input className="Checkbox" type="checkbox"></input>
                                                <p>Subcribe to marketing from this dispensary</p>
                                            </div> */}
                                        </>
                                    ) : null
                                }



        
                                                            
                            </form>
                        </div>
                        <div style={{marginTop:30}} className="Checkout-Container">
                            <label style={{marginTop:15}}>Summary</label>

                            
                            <div style={{paddingTop:10, paddingBottom:8, paddingLeft:15, paddingRight:15, borderRadius:5, background: backgroundColor, border: "1px solid #D6D6D6", marginTop:15, alignItems:'center',  display:'flex', flexDirection:'row'}}>
                            <img style={{width:40, marginRight:15}} src={retailerLogo}></img>

                                <div>
                                    <p style={{color: textColor, fontSize: 12, margin:0}}>{retailer}</p>
                                </div>
                                <div style={{marginLeft: 'auto'}}>
                                    {/* <span style={{fontSize:12}} className="Checkout-Summary-Quantity">Est pickup: 25 - 30 mins</span> */}
                                </div>
                            </div>

                            {
                                products.length > 0 ? (
                                    <>
                                        {products.map((product, i) => (

                                            <div style={{paddingTop:8, paddingBottom:8, paddingLeft:15, paddingRight:15, borderRadius:5, background: backgroundColor, border: "1px solid #D6D6D6", marginTop:10, marginBottom:5, alignItems:'center',  display:'flex', flexDirection:'row'}}>
                                                <img style={{width:40,  marginRight:15}} src={product.Image}></img>
                                                <div style={{maxWidth:225}}>
                                                    <h5 style={{color: secondaryTextColor,fontSize:12, fontWeight:600, marginTop:0, marginBottom:0}}>{product.brandName}</h5>
                                                    <p style={{color: textColor, fontSize: 12, margin:0}}>{product.Name}</p>
                                                </div>
                                                <div style={{marginLeft: 'auto', display:"flex", flexDirection:"row", alignItems:"center"}}>
                                                    <span style={{fontSize:12, marginRight:15}} className="Checkout-Summary-Quantity">
                                                    {product.ATCQUANTITY} x 
                                                    </span>
                                                    <span style={{fontSize:12}} className="Checkout-Summary-Quantity">
                                                        <div style={{display:"flex", flexDirection:"column"}}>
                                                            {product.OverridePrice ? (
                                                                <>
                                                                    <span style={{marginRight:5, color: "red", textDecoration: "line-through"}}>
                                                                        ${product.Prices[0].toFixed(2)}
                                                                    </span>
                                                                    ${product.OverridePrice.toFixed(2)}
                                                                </>
                                                            ) : 
                                                                <>
                                                                    ${product.Prices[0].toFixed(2)}
                                                                </>
                                                            }
                                                        </div>
                                                        
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : null
                            }

                            <div style={{paddingTop:10, paddingBottom:8, paddingLeft:15, paddingRight:15, borderRadius:5, background: backgroundColor, border: "1px solid #D6D6D6", marginTop:15, alignItems:'center',  display:'flex', flexDirection:'row'}}>
                                <div>
                                    <p style={{color: textColor, fontSize: 12, margin:0}}>Tax</p>
                                </div>
                                <div style={{marginLeft: 'auto'}}>
                                    <span style={{fontSize:12, color: textColor}} className="Checkout-Summary-Quantity">${tax}</span>
                                </div>
                            </div>



                                                
                            <div style={{paddingTop:10, paddingBottom:8, paddingLeft:15, paddingRight:15, borderRadius:5, background: backgroundColor, border: "1px solid #D6D6D6", marginTop:15, alignItems:'center',  display:'flex', flexDirection:'row'}}>
                                <div>
                                    <p style={{color: textColor, fontSize: 12, margin:0}}>Total</p>
                                </div>
                                <div style={{marginLeft: 'auto'}}>
                                    <span style={{fontSize:12,  color: textColor}} className="Checkout-Summary-Quantity">${total}</span>
                                </div>
                            </div>
                            <div style={{marginTop:15, display: 'flex', justifyContent:'center'}}>
                                <button 
                                disabled={disabled}
                                className="Checkout-Button" onClick={() => {
                                    console.log("button", disabled);
                                    if (disabled) {
                                        return;
                                    } else {
                                        handleSubmit(onSubmit)()
                                    }
                                }}>Place Order</button>
                            </div>

                            <div style={{marginTop:15, display: 'flex', justifyContent:'center'}}>
                                <button style={{backgroundColor:'rgba(184, 184, 184, 1)'}} className="Checkout-Button" onClick={() => {
                                    window.location.href = `/${retailerId}`;
                                }}>Continue Shopping</button>
                            </div>

                            {/* <a>Continue Shopping</a> */}
                            <p style={{maxWidth: '75%', margin:'auto', marginTop:10, fontSize: 10, color: secondaryTextColor, textAlign:'center'}}>By placing an order you agree to our Terms and receiving automated updates via text</p>
                        </div>
                   

                </div>

          

            </div>

        <div className="Footer">
            <a href="https://heed.chat" target="_blank">Powered by <span style={{color:"#E17E76", fontWeight:"bold"}}>heed</span></a>
        </div>
        </div>
    )
}

export default Checkout;
