import React, { useEffect, useState, useRef } from "react";
import "./Heed.css"
import logo from "./logo.svg";
import close from "./close.svg";


const cateogires = [
    {
        image: "https://i.imgur.com/wXa3kcT.png",
        name: "Flower",
        filters: {}
    },
    {
        image: "https://i.imgur.com/qJRbhnd.png",
        name: "Pre-Rolls",
        filters: {}
    },
    {
        image: "https://i.imgur.com/lylC5BP.png",
        name: "Edible",
        filters: {}
    },
    {
        image: "https://i.imgur.com/sVI3rv3.png",
        name: "Concentrate",
        filters: {}
    },
    {
        image: "https://i.imgur.com/sW3THLV.png",
        name: "Vaporizers",
        filters: {}
    },
    {
        image: "https://i.imgur.com/qBOe2qi.png",
        name: "Topicals",
        filters: {}
    },

];

const subcategoryList = {
    flower: [],
    "pre-rolls":[
        "singles",
        "pre-roll packs",
        "infused pre-rolls"
    ],
    edible: [
        "chocolates",
        "gummies",
        "baked goods",
        "drinks",
        "capsules",
        "chews",
        "hard candy",
        "sublingual products",
        "savory snacks",
        "dissolvables",
        "lozenges",
    ],
    concentrate: [
        "live resin",
        "rosin",
        "kief",
        "applicators",
        "sauce",
        "diamonds",
    ],
    vaporizers: [
        "cartridges",
        "pods",
        "disposables"
    ],
    topicals: [
        "topical oils",
        "lotions",
        "roll-ons",
        "bath products"
    ]
}

const feelings = [
    {
        image: "https://heed.nyc3.digitaloceanspaces.com/new_spark.png",
        name: "Spark",
        filters: {}
    },
    {
        image: "https://heed.nyc3.digitaloceanspaces.com/new_unwind.png",
        name: "Unwind",
        filters: {}
    },
    {
        image: "https://heed.nyc3.digitaloceanspaces.com/new_social.png",
        name: "Social",
        filters: {}
    },
    {
        image: "https://heed.nyc3.digitaloceanspaces.com/new_create.png",
        name: "Create",
        filters: {}
    },
    {
        image: "https://i.imgur.com/lEq7jyz.png",
        name: "Calm",
        filters: {}
    }
]

const ProductCard = ({ product, setHeedOpen, setShowCart }) => {
    return (
        <div onClick={() => {
            var cartObj = JSON.parse(localStorage.getItem('heed-cart')) ? JSON.parse(localStorage.getItem('heed-cart')) : {};
            
            if (!cartObj[product.id]) {
              cartObj[product.id] = [];
            }
            
            for (var i = 0; i < parseInt(1); i++) {
              cartObj[product.id].push(product);
            }
        
            var cartWeight = 0;
        
            Object.values(cartObj).map((productArr, i) => {
                var product = productArr[0];
                var productAmount = productArr.length;
                var netWeight = parseFloat(product.measurements)* productAmount;
                cartWeight+=netWeight;
            });
        
            // Calculate updated cart weight
            if (cartWeight > 30) {
              alert("Sorry! You've reached the 30g purchase limit for flower due to federal regulations.")
            } else {
              console.log('ATC weight is', cartWeight);
              localStorage.setItem('heed-cart', JSON.stringify(cartObj));
              setHeedOpen(false);
              setShowCart(true);
            }
        }} className="Widget-Product-Card Product-Card">
            <img src={product.Image}></img>

            <div style={{maxWidth: "90%"}}>
                <span className="Product-Card-Price">
                    {product.OverridePrice ? (
                        <>
                            <span style={{marginRight:5, color: "red", textDecoration: "line-through"}}>
                                ${product.Prices[0].toFixed(2)}
                            </span>
                            ${product.OverridePrice.toFixed(2)}
                        </>
                    ) : 
                       <>
                        ${product.Prices[0].toFixed(2)}
                        </>
                    }
                </span>
                <h2 className="Product-Card-Title">{product.Name}</h2>
                <h5 className="Product-Card-Brand">{product.brandName}</h5>
                <p style={{marginTop:5, fontWeight:'bold'}} className="Product-Card-Info">{product.strainType.toUpperCase()}</p>
                {
                    product.THCContent && product.THCContent.range && product.THCContent.range.length > 0 ? (
                        <p className="Product-Card-Info">
                            {
                                    product.THCContent && product.THCContent.range && product.THCContent.range.length > 0 ? (
                                        product.THCContent.unit === "MILLIGRAMS" ? (
                                            `THC: ${product.THCContent.range[product.THCContent.range.length-1]}mg`
                                        ) : (
                                            `THC: ${product.THCContent.range[0]}% - ${product.THCContent.range[product.THCContent.range.length-1]}%`
                                        )
                                    ) : null
                                }
                        </p>                         
                    ) : null
                }
                {
                    product.CBDContent && product.CBDContent.range && product.CBDContent.range.length > 0 ? (
                        <p className="Product-Card-Info">
                             {
                                    product.CBDContent && product.CBDContent.range && product.CBDContent.range.length > 0 ? (
                                        product.CBDContent.unit === "MILLIGRAMS" ? (
                                            `CBD: ${product.CBDContent.range[product.CBDContent.range.length-1]}mg`
                                        ) : (
                                            `CBD: ${product.CBDContent.range[0]}% - ${product.CBDContent.range[product.CBDContent.range.length-1]}%`
                                        )
                                    ) : null
                            }
                        </p>                         
                    ) : null
                }
            </div>

        </div>
    )
}




const Heed = ({ heedOpen, setHeedOpen, retailerId, setShowCart}) => {
    const hostname = "https://heed.herokuapp.com";
    // const hostname = "http://localhost:5000";
    const [openChat, setOpenChat] = useState(false);
    const [showProducts, setShowProducts] = useState([]);

    const [step, setStep] = useState("categories");
    const [category, setCategory] = useState(null);
    const [subcategory, setSubcategory] = useState(null);
    const [feeling, setFeeling] = useState(null);
    const messageEndRef = useRef(null);
    const [messages, setMessages] = useState([
        {
            sender: true,
            message: "Find a product"
        },
        {
            sender: false,
            message: "What kind of product are you looking for?"
        }
    ])

    const [subcategories, setSubCategories] = useState([]);
    const subcategoryImages = {
        "flower": "https://i.imgur.com/wXa3kcT.png",
        "pre-rolls": "https://i.imgur.com/qJRbhnd.png",
        "edible": "https://i.imgur.com/lylC5BP.png",
        "concentrate": "https://i.imgur.com/sVI3rv3.png",
        "vaporizers": "https://i.imgur.com/sW3THLV.png",
        "topicals": "https://i.imgur.com/qBOe2qi.png"
    }

    useEffect(() => {
        if (heedOpen) {
            setShowCart(false);
        }
    }, [heedOpen]);

    useEffect (() => {
        if (category) {


            let list = subcategoryList[category.toLowerCase()];
            if (list && list.length > 0) {
                let filter = {
                    type: ["Sativa", "Indica", "Hybrid", "High CBD", "Blend"],
                    categories: [category]
                };
                console.log(filter);
                fetch( `${hostname}/api/menu/${retailerId}/products`, {    headers: { 'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify({filters: filter}), crossDomain:true })
                .then((response) => response.json())
                .then((response) => {
                    if (response.success) {
                        var availableCategories = response.data.map((productData) => productData.subcategory);                
    
                        availableCategories.sort(function(a,b) {
                            return a.localeCompare(b);
                        });
    
                        var finalCategories = [...new Set(availableCategories)];
                        setSubCategories(finalCategories);
                        var ms = messages;
                        ms.push({sender: false, message: `What kind of ${category} are you looking for?`});
                        setMessages(ms);
                        setStep("subcategories");
                    }
                }).catch(() => {
                    var ms = messages;
                    ms.push({sender: false, message: "Sorry there was an issue... Please try again!"});
                    setMessages(ms);
                    setStep("categories");
                })
            } else {
                var ms = messages;
                ms.push({sender: false, message: `How do you want to feel?`});
                setMessages(ms);
                setStep("feelings");
            } 
        }
    }, [category]);


    useEffect(()=> {
       if (step == "products") {
            // Fetch Products
            getProducts();
        }
    }, [step]);
    

    const scrollToBottomWithSmoothScroll = () => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollTo({
                top: messageEndRef.current.scrollHeight,
                behavior: 'smooth',
            })
        } 
    }

    useEffect(() => {
        scrollToBottomWithSmoothScroll();
    }, [messages, showProducts, step]);

    const getProducts = () => {
        let filter = {
            categories: [category]
        };


        // Spark: Sativa, High THC
        if (feeling == "Spark") {
            filter.type = ["Sativa"]
            filter.thc = [18, 100]
        }
        // Unwind: Indica, High THC
        else if (feeling == "Unwind") {
            filter.type = ["Indica"]
            filter.thc = [18, 100]
        }
        // Social: Hybrid, Blend, Low THC
        else if (feeling == "Social") {
            filter.type = ["Hybrid", "Blend"]
            filter.thc = [0, 18]
        }
        // Create: Hybrid, Blend, High THC
        else if (feeling == "Create") {
            filter.type = ["Hybrid", "Blend"]
            filter.thc = [18, 100]
        }
        // Calm: CBD
        else if (feeling == "Calm") {
            filter.type = ["High CBD"]
        }

        if (subcategory) {
            filter.subcategories = [subcategory];
        }

        
        // Fetch Products
        fetch( `${hostname}/api/menu/${retailerId}/products`, {    headers: { 'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify({filters: filter}), crossDomain:true })
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                if (response.data.length == 0) {
                    setTimeout(() => {
                        var ms = messages;
                        ms.push({sender: false, message: "Sorry we couldn't find any matching products... Please try with another subcategory!"});
                        setMessages(ms);
                        setCategory(null);
                        setSubcategory(null);
                        setFeeling(null);
                        setStep("categories");
                        return;
                    }, 4000);

                    return;

                }

                var ms = [...messages];
                setTimeout(() => {
                    ms.push({sender: false, message: `Here's some products I found for you!`});
                    setMessages(ms);
                    
                }, 4000);
         

                // Shuffle products
                for (let i = response.data.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [response.data[i], response.data[j]] = [response.data[j], response.data[i]];
                }
                let prods = response.data.slice(0, 3)
                setTimeout(() => {
                    setShowProducts(prods);
                }, 5000);
       
            } else {
                setTimeout(() => {
                    var ms = messages;
                    ms.push({sender: false, message: "Sorry we couldn't find any matching products... Please try again!"});
                    setMessages(ms);
                    setCategory(null);
                    setSubcategory(null);
                    setFeeling(null);
                    setStep("categories");
                    return;
                }, 4000);
                
            }
        }).catch(() => {
            setTimeout(() => {
                var ms = messages;
                ms.push({sender: false, message: "Sorry we couldn't find any matching products... Please try again!"});
                setMessages(ms);
                setCategory(null);
                setSubcategory(null);
                setFeeling(null);
                setStep("categories");
                return;
            }, 4000);
            
        })

    }


    return (
        <>
            {/* Widget Circle */}
            <div onClick={() => {setHeedOpen(!heedOpen)}} className={`Heed-Widget cssanimation fadeInBottom ${heedOpen ? "active" : ""}`}>            
                <div className="Logo-Container">
                    <img className="Logo-Widget" src={logo}></img>
                </div>

                <div className="Close-Container">
                    <img className="Close-Widget" src={close}></img>
                </div>
            </div>

            {/* Widget menu */}
         
            <div style={{height: heedOpen ? 500 : 0, bottom: heedOpen ? 125 : -30, opacity: heedOpen ? 1 : 0.3}} className="Heed-Widget-Body">
                <div style={{justifyContent: `${openChat ? "space-between" : "flex-end"}`}} className="Heed-Widget-Body-Header">
                    {
                        openChat ? (
                            <button onClick={() => {setOpenChat(false)}}> {"<"} </button>
                        ) : null
                    }

                    <button onClick={() => {setHeedOpen(!heedOpen)}}>x</button>
                </div>
                {
                    !openChat ? (
                        <div className="Heed-Widget-Menu">
                            <h1>Hello 👋<br></br> I’m Heed!<br></br> I help you<br></br> discover weed.</h1>
                            <button onClick={() => {setOpenChat(true)}} className="Find-Product">Find Product</button>
                            {/* <button onClick={() => {}} className="Start-Chat">Chat</button> */}
                        </div>

                    ) : (
                        <div className="Heed-Widget-Chat" ref={messageEndRef}>

                        
                            <>
                            <div className="Heed-Widget-Chat-Messages">

                                {messages.map((message, index) => {
                                    return (
                                        <div key={index} className={`Heed-Widget-Chat-Message-Row ${message.sender ? "My-Message" : "Other-Message"}`}>
                                            <div className="Heed-Widget-Chat-Bubble">
                                                {message.message}
                                            </div>
                                        </div>
                                    )
                                })}

    



                            </div>


                            {step === "categories" || step === "feelings" || step === "subcategories" ? (
                                <div className="Heed-Widget-Chat-Cards-Container">
                                    <h5>Choose an Option</h5>
                                
                                    <div className="Heed-Widget-Chat-Cards">
                                        {
                                            step === "categories" ? (
                                                <>
                                                    {cateogires.map((category, index) => {
                                                        return (
                                                            <div onClick={() => {
                                                                var ms = [...messages];
                                                                ms.push({sender: true, message: `${category.name}`});
                                                                setMessages(ms);
                                                                setStep("");
                                                                setTimeout(() => {
                                                                    ms.push({sender: false, message: `I can help you find some ${category.name}`});
                                                                    setMessages(ms);
                                                                }, 500);


                                                                setTimeout(() => {
                                                                    setCategory(category.name);
                                                                }, 1000);
                                        
                                                            }} className="Heed-Widget-Chat-Card">
                                                                <img src={category.image}></img>
                                                                <h6>{category.name}</h6>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            ) : null
                                        }

                                        {step === "subcategories" ? (
                                                <>
                                                <div onClick={() => {
                                                    var ms = [...messages];
                                                        ms.push({sender: true, message: `All`});
                                                        setMessages(ms);
                                                        setSubcategory(null);
                                                        setTimeout(() => {
                                                            ms.push({sender: false, message: `Perfect!`});
                                                            setMessages(ms);
                                                        }, 1000);

                                                        
                                                        setTimeout(() => {
                                                            ms.push({sender: false, message: `How do you want to feel?`});
                                                            setStep("feelings");
                                                        }, 2000);

                                                       
                                                    }} className="Heed-Widget-Chat-Card">
                                                        <img src={subcategoryImages[category.toLowerCase()]}></img>
                                                        <h6>all</h6>
                                                </div>

                                                {subcategories.map((subcategory, index) => {
                                                    return (
                                                    subcategory !== "" ? (
                                                        <div onClick={() => {
                                                            var ms = [...messages];
                                                            ms.push({sender: true, message: subcategory});
                                                            setMessages(ms);
                                                            setSubcategory(subcategory);
                                                            setStep("");
                                                            setTimeout(() => {
                                                                ms.push({sender: false, message: `Good choice!`});
                                                                setMessages(ms);
                                                            }, 1000)
                                                     
                                                            setTimeout(() => {
                                                                ms.push({sender: false, message: `How do you want to feel?`});
                                                                setMessages(ms);
                                                                setStep("feelings");
                                                            }, 2000);

                                                        }} className="Heed-Widget-Chat-Card">
                                                            <img src={subcategoryImages[category.toLowerCase()]}></img>
                                                            <h6>{subcategory}</h6>
                                                        </div>) : null
                                                    )
                                                })}
                                            </>
                                        ) : null}


                                        {
                                            step === "feelings" ? (
                                                <>
                                                    {feelings.map((feeling, index) => {
                                                        return (
                                                            <div onClick={() => {
                                                                var ms = [...messages];
                                                                ms.push({sender: true, message: `${feeling.name}`});
                                                                setMessages(ms);
                                                                setTimeout(() => {
                                                                    ms.push({sender: false, message: `Let me see what I can find...`});
                                                                    setMessages(ms);
                                                                }, 2000);
                                                                setFeeling(feeling.name);
                                                                setStep("products");
                                                          
                                                            }} className="Heed-Widget-Chat-Card">
                                                                <img src={feeling.image}></img>
                                                                <h6>{feeling.name}</h6>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            ) : null
                                        }



                                    </div>

                                </div>
                            ) : null}

                            </>


                            {
                                showProducts.length > 0 ? (
                                    <div className="Heed-Widget-Product-Cards-Container">
                                        <h5 style={{textAlign:"center", fontSize:14, color: "#6C6C6C", fontWeight:800, textShadow: "2px 2px 8px rgba(104, 104, 104, 0.25)"}}>Recommended Products</h5>
                                        

                                        <div className="Heed-Widget-Chat-Products">
                                            {
                                                showProducts.map((product, index) => {
                                                    return (
                                                        <ProductCard setHeedOpen={setHeedOpen} setShowCart={setShowCart} product={product}></ProductCard>
                                                    )
                                                })
                                            }
                                        </div>

                                        <div className="Heed-Widget-Product-Buttons">
                                            <button 
                                            onClick={() => {
                                                getProducts();
                                            }}
                                            style={{border:"1px solid #9F9F9F", backgroundColor:"#5096FF", color: "#fff", boxShadow:"14.9356px 14.9356px 29.8712px rgba(162, 162, 162, 0.5), inset -7.46781px -4.97854px 9.95708px #2C80FF, inset 7.46781px 4.97854px 9.95708px rgba(240, 240, 240, 0.4)"}}>New Products</button>
                                            <button 
                                                onClick={()=>{
                                                    setFeeling(null);
                                                    setCategory(null);
                                                    setSubcategory(null);
                                                    setShowProducts([]);
                                                    setStep("categories");
                                                    setMessages([
                                                        {
                                                            sender: true,
                                                            message: "Find a product"
                                                        },
                                                        {
                                                            sender: false,
                                                            message: "What kind of product are you looking for?"
                                                        }
                                                    ]);
                                                    // setOpenChat(false);
                                                }}
                                            style={{border: "1px solid #E6E6E6", color: "#6C6C6C", backgroundColor: "#FFF2EC", boxShadow:"24px 24px 39.2308px rgba(197, 197, 197, 0.5), inset 12px 8px 24px rgba(255, 255, 255, 0.35), inset -12px -8px 24px #EEDBD2"}}>Main Menu</button>
                                        </div>

                                    
                                    </div>
                                ) : null
                            }
                            


                        </div>
                    )
                }
                

                <div className="Heed-Widget-Body-Footer">
                    <p>powered by <a target="_blank" href="https://heed.chat"> heed</a></p>
                </div>

                {/* {
                    !openChat ? (
                        <div className="Heed-Widget-Body-Footer">
                            <p>powered by <a target="_blank" href="https://heed.chat"> heed</a></p>
                        </div>
                    ) : null
                } */}
    
            </div>
               
            

        </>
    )
}

export default Heed 