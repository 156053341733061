import React, { useEffect, useState, useRef } from "react";
import CloseIcon from "./CloseIcon.png";

const MenuNavigationHeader = ({ hours, showCategories, setShowCategories, showInfo, setShowInfo }) => {

    const [open, setOpen] = useState(true);

    useEffect(() => {
        let hourDates = Object.keys(hours);
        const d = new Date();
        let day = d.getDay();
        var hour = d.getHours();
        var minute = d.getMinutes();

        var currentTime = `${hour}:${minute}:00`;

        var openTime = `${hours[hourDates[day]].open[0].toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${hours[hourDates[day]].open[1].toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:00`;
        var closeTime = `${hours[hourDates[day]].close[0].toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${hours[hourDates[day]].close[1].toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:00`;

        if (openTime <= currentTime && currentTime <= closeTime) {
            console.log("location is opened!");
            setOpen(true);
        } else {
            console.log("location is closed!");
            setOpen(false);
        }
    
    }, []);


    return (
        <div className="Header Navigation-Header">
            <div className="Header-Container">
                <div style={{display:'flex', alignItems:'center'}}>
                    <span className={`Menu-Status ${open ? "green" : "red"}`}>{open ? "OPEN" : "CLOSED"}</span>
                </div>


                <div onClick={() => {setShowInfo(false);setShowCategories(false)}} style={{cursor:'pointer',display:'flex', alignItems:'center'}}>
                    <img style={{width: 30, height:30}} src={CloseIcon}/>
                </div>

            </div>
                
        </div>
    )
}

export default MenuNavigationHeader